import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import './index.scss';
import Video from '../components/video';
import ArrowRightIcon from '!svg-react-loader!../images/vectors/arrow_right.svg';
import { navigate } from 'gatsby';
import BookDemoPage from '../components/book-demo';
import SRBookDemoPage from '../components/book-demo/book-smart-rep';
import { viewFeedbackDummyData } from '../utils/constants';
import { InstallButton } from '../components/extra-components';

const videoElem = (<Video
  videoSrcURL={`https://www.youtube.com/embed/Lv59h1zDl-U?start=120&autoplay=1&mute=1`}
  videoTitle={`Some Random Title`}
/>);


const ViewFeedbackPageHeader = ({ data, rightButton }) => {
  const onBackPressed = () => {
    navigate(-1);
  };

  let BookDemoElement = BookDemoPage;
  if (data.productType === 'sa') {
    BookDemoElement = BookDemoPage;
  }
  if (data.productType === 'sr') {
    BookDemoElement = SRBookDemoPage;
  }
  if (data.productType === 'myhb') {
    BookDemoElement = InstallButton;
  }

  return (
    <div className='fp-header container'>
      <div className='row'>
        <button className='btn col-auto back-image' onClick={onBackPressed}>
          <ArrowRightIcon />
        </button>
        <div className='col'>
          <h4 className='primary-header-color'>{data.name}</h4>
          <p>{data.description}</p>
          <div className='fz-small'>using since: {data.startDate}</div>
        </div>
        <div className='col-auto desktop'>
          {rightButton ? rightButton : <BookDemoElement cType={data.cType} pType={'view-feedback'} dynamic={true} />}
        </div>
      </div>

    </div>
  );
};

const MicroappRatingComments = ({ data }) => {
  return (
    <div className='fp-microapp-rating-card'>
      <h4 className='font-sf-semibold fz-page-internal-header'>{data.title}: {data.rating}/{data.ratingMax}</h4>
      <p className='primary-content-color'>{data.comments}</p>
    </div>
  );
};

const ViewFeedbackPage = ({ location, navigate }) => {
  const params = new URLSearchParams(location.search);
  const feedbackId = parseInt(params.get('id'));
  console.log('feedback id: ', typeof feedbackId);

  let data = viewFeedbackDummyData[feedbackId] || viewFeedbackDummyData[101];
  const ignoreReachout = data.ignoreReachout;

  const ratingsObj = data.ratings.reduce((acc, i) => {
    acc[i.feature] = i;
    return acc;
  }, {});
  const overallRating = ratingsObj.overall;
  const [ playing, setPlaying ] = useState(true);
  const reachoutClicked = () => {
    console.log('reachout clicked');
    setPlaying(!playing);
  };

  return (
    <div className="mt-page-root container">
      <ViewFeedbackPageHeader data={data} />
      <div className='container-fluid fp-container primary-content-color'>
        <div className='fp-microapps'>
          <h4 className='font-sf-medium fz-page-internal-header primary-header-color'>Most used micro apps:</h4>
          <p className='font-sf-light fz-page-content'>{data.mostUsedMicroapps.map(x => x.name).join(', ')}</p>
        </div>
        <div className='fp-feedback'>
          <h4 className='font-sf-medium fz-page-internal-header primary-header-color'>Our
            Feedback: {overallRating.rating}/{overallRating.ratingMax}</h4>
          <div className='fp-video-container'>
            <ReactPlayer
              url='https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4'
              playing={playing}
              muted={false}
              width={'100%'}
              height='auto'
              controls
            />
            <button
              className={`btn fp-reachout container ${ignoreReachout ? 'ignore-reachout' : ''}`}
              onClick={reachoutClicked}
            >
              <div className='row'>
                <div className='col-10'>
                  <p className='fz-page-content font-sf-light'>Schedule call with {data.name} for detailed
                    feedback</p>
                </div>
                <div className='col-2 content-align-center'>
                  <ArrowRightIcon />
                </div>
              </div>
            </button>
          </div>
        </div>
        <div className='fp-ratings-comments'>
          {
            data.ratings
              .filter(a => a.feature !== 'overall')
              .map(item => <MicroappRatingComments key={item.feature} data={item} />)
          }
        </div>
      </div>
      <div className='mobile'>
        <BookDemoPage location={location} navigate={navigate} bookDemoClicked={() => {
          setPlaying(false);
        }} />
      </div>

    </div>

  );
};

export default ViewFeedbackPage;
